@import '../config.scss';

nav {
  background-color: $c0;
  position: fixed;
  text-align: left;
  box-sizing: border-box;
  top: 0;
  width: 100%;
  color: $c9;
  font-weight: bold;
  padding: $md $lg;
  max-width: 450px;
  margin: 0 auto;
  > a {
    color: $c9;
    text-decoration: none;
    cursor: pointer;
  }
  > ul.hamburger{
    list-style: none;
    margin: 0;
    padding: 0;
    height: 15px;
    margin-right: $md;
    margin-top: 3px;
    width: 15px;
    float: left;
    cursor: pointer;
    > li {
      background-color: white;
      width: 100%;
      height: 2px;
      margin-bottom: 3px;
    }
    > li:last-child {
        margin-bottom: 0;
    }
  }
  >span {
      font-weight: bold;
      margin-right: 17px;
      cursor: pointer;
  }
  > ul.menu {
      font-weight: normal;
      margin: $md 0 0 0;
      padding: 0;
      list-style: none;
      > li {
        > a {
          color: $c9;
          text-decoration: none;
          font-weight: bold;
        }
          margin: 0;
          padding: $sm;
          border-bottom: solid thin $c4;
      }
      > li:last-child {
          border-bottom: none;
          font-size: $f0;
          font-weight: normal;
          font-weight: 400;
      }
  }
}
