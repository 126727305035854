@import '../config.scss';

section.Profile {
  background-color: $c0;
  color: $c9;
  padding-bottom: $lg;
  > div.banner {
    height: 125px;
    background-color: $c1;
    margin-top: 43px;
  }
  > img.profile {
    width: 150px;
    border-radius: 100%;
    display: block;
    margin: -75px auto 0 auto;
    border: solid thick $c3;
  }
  > h2 {
    text-align: center;
  }
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 0 $lg;
    font-size: $f0;
    > li {
      > a {
        color: $c9;
        font-weight: bold;
        text-decoration: none;
      }
      > i {
        margin-right: $md;
      }
      margin-bottom: $md;
    }
  }
  > p {
    box-sizing: border-box;
    padding: $lg;
    font-size: $f0;
    > a {
      font-weight: bold;
      color: $c9;
      text-decoration: none;
    }
  }
  > ul.resources {
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      > div.article-image {
        height: 225px;
        background-size: cover;
        background-color: $c2;
        background-position: center center;
      }
      > a {
        display: block;
        background-color: $c6;
        box-sizing: border-box;
        padding: $sm $md;
        > p:first-child {
          font-size: $f0;
          color: $c3;
          margin-bottom: 0;
          text-transform: uppercase;
        }
        > p:last-child {
          margin-top: 0;
          font-weight: bold;
          font-size: $f2;
          text-transform: capitalize;
          color: $c2;
        }
      }
      > iframe {
        width: 100%;
        height: 225px;
        border: none;
        margin-bottom: -3px;
      }
    }
  }
}
