$c0: #000000;
$c1: #161616;
$c2: #1b1b1b;
$c3: #353535;
$c4: #5c5c5c;
$c5: #838383;
$c6: #acacac;
$c9: #FFFFFF;

$sm: 7px;
$md: 12px;
$lg: 16px;

$f0: 0.85em;
$f1: 1em;
$f2: 1.15em;